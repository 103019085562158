import {IconButton, Link, Stack, Typography} from "@mui/material";
import {Copyright, LinkedIn} from "@mui/icons-material";

export default function Footer() {

    return (
        <Stack sx={{width: '100vw', pt: 5, px: 5}}
               direction={'column'}
               justifyContent={'center'}
               alignItems={'center'}
               spacing={4}>
            <Typography variant={'caption'} color={'text.secondary'}>
                1 - According to <Link href={"https://www.fooddrinkeurope.eu/resource/data-trends-of-the-european-food-and-drink-industry-2021/"}>FoodDrinkEurope</Link> - Data & Trends of the European Food and Drink Industry 2021
            </Typography>
            <Stack direction={"row"}
                   justifyContent={'center'}
                   alignItems={'center'}
                   spacing={1}>
                <Typography variant={'body2'} color={'text.secondary'}>
                    All Rights Reserved
                </Typography>
                <Typography variant={'body2'} color={'text.secondary'}>
                    |
                </Typography>
                <Typography variant={'body2'} color={'text.secondary'}>
                    <Copyright sx={{fontSize: 10}} /> OmniumAI 2023
                </Typography>
                <Typography variant={'body2'} color={'text.secondary'}>
                    |
                </Typography>
                <Link variant={'body2'}
                      color={'text.secondary'}
                      href={"/privacy"}>
                    Privacy Policy
                </Link>
                <Typography variant={'body2'} color={'text.secondary'}>
                    |
                </Typography>
                <IconButton href={'https://www.linkedin.com/company/omniumai'}>
                    <LinkedIn/>
                </IconButton>
            </Stack>
        </Stack>
    );
}
import {useState} from "react";
import PropTypes from 'prop-types';
// @mui
import {alpha, styled} from '@mui/material/styles';
import {
    Box,
    AppBar,
    Toolbar,
    Button,
    IconButton,
    Drawer,
    List,
    ListItemButton,
    ListItem,
    ListItemText, Link, Divider, Stack, Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// routes
import {Link as RouterLink} from "react-router-dom";
// scroll
import { Link as ScrollLink } from 'react-scroll'

// ----------------------------------------------------------------------

const NAV_WIDTH = 0;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;


function bgBlur(props) {
    const color = props?.color || '#000000';
    const blur = props?.blur || 6;
    const opacity = props?.opacity || 0.8;
    const imgUrl = props?.imgUrl;

    if (imgUrl) {
        return {
            position: 'relative',
            backgroundImage: `url(${imgUrl})`,
            '&:before': {
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 9,
                content: '""',
                width: '100%',
                height: '100%',
                backdropFilter: `blur(${blur}px)`,
                WebkitBackdropFilter: `blur(${blur}px)`,
                backgroundColor: alpha(color, opacity),
            },
        };
    }

    return {
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: alpha(color, opacity),
    };
}

const StyledRoot = styled(AppBar)(({theme}) => ({
    ...bgBlur({color: theme.palette.background.default}),
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
}));

const StyledToolbar = styled(Toolbar)(({theme}) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
    onOpenNav: PropTypes.func,
};

const pages = [
    {
        name: "Product",
        link: "product"
    },
    {
        name: "Privacy",
        link: "privacy"

    },
    {
        name: "Contact",
        link: "contact"

    }
]

export default function Header() {
    const [state, setState] = useState(false);

    function openNav() {
        setState(true)
    }

    function closeNav() {
        setState(false)
    }

    return (
        <StyledRoot>
            <StyledToolbar>
                <Box sx={{flexGrow: 1}}>
                    <Link
                        sx={{cursor: 'pointer'}}
                        underline="none"
                        href={"/home"}>
                        <img src="/assets/cibus_B.png" alt="CibusAI" height={50}/>
                    </Link>
                </Box>
                <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="info"
                        aria-label="menu"
                        sx={{mr: 2}}
                        onClick={openNav}>
                        <MenuIcon/>
                    </IconButton>
                </Box>
                <Box sx={{display: {xs: 'none', md: 'flex'}, flexGrow: 1}}>
                    {pages.map((page) => (
                        <ScrollLink
                            style={{cursor: 'pointer', color: '#2065D1', margin: '0 10px'}}
                            to={page.link}
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-100}>
                            <Typography variant={"h6"}>{page.name}</Typography>
                        </ScrollLink>
                    ))}
                </Box>

                <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                    <Stack direction={"row"} spacing={3}>
                        <Button
                            variant="outlined"
                            component={RouterLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            to="https://www.app.cibus.omniumai.com/">
                            Sign In
                        </Button>
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to={"mailto:info@omniumai.com"}>
                            Get Early Access
                        </Button>
                    </Stack>
                </Box>
                <Drawer
                    PaperProps={{sx: { width: "40%" }}}
                    anchor="right"
                    open={state}
                    onClose={closeNav}>
                    <List sx={{pt: 5}}>
                        {pages.map((page) => (
                            <ListItem key={page.name}>
                                <ScrollLink
                                    onClick={closeNav}
                                    style={{cursor: 'pointer', color: '#2065D1', margin: '5px 0'}}
                                    to={page.link}
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    offset={-100}>
                                    {page.name}
                                </ScrollLink>
                            </ListItem>
                        ))}
                    </List>
                    <Divider/>
                    <List>
                        <ListItem key={1}>
                            <ListItemButton
                                onClick={closeNav}
                                component={RouterLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                to="https://www.app.cibus.omniumai.com/">
                                <ListItemText primary={"Sign In"}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem key={2}>
                            <ListItemButton
                                onClick={closeNav}
                                component={RouterLink}
                                to={"mailto:info@omniumai.com"}>
                                <ListItemText primary={"Get Early Access"}/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Drawer>
            </StyledToolbar>
        </StyledRoot>
    );
}

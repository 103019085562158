import {Helmet} from 'react-helmet-async';
// @mui
import {Grid, Container, Typography, Stack, Button, Box, Avatar, Link} from '@mui/material';
import {
	AccessTime,
	DiamondOutlined,
	FavoriteBorder,
	PeopleOutline,
	Security,
	VisibilityOff
} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// routes
import {Link as RouterLink} from "react-router-dom";

// components
import UseCaseCard from "../components/use-case-card";

// scroll
import { Element } from 'react-scroll';

// ----------------------------------------------------------------------
const DesktopDashboardSection = () => {
	return (
		<>
			<Grid item xs={4}>
				<Typography variant="h1" sx={{pt: 5}}>
					Reimagining food
				</Typography>
				<Typography
					sx={{my: 5}}
					color="text.secondary"
					variant="h4"
					paragraph
				>
					<Typography color={"primary"}
								component={'span'}
								variant={"h4"}>
						CibusAI {' '}
					</Typography>
					empowers food companies with AI-driven reformulation, reimagining their products for better
					<Typography color={"success.main"}
								component={'span'}
								variant={"h4"}>{' '} taste {' '}
					</Typography> and
					improved <Typography color={"error.main"}
										 component={'span'}
										 variant={"h4"}>health
				</Typography>.
				</Typography>
				<Box>
					<Button color={'primary'}
							variant="contained"
							component={RouterLink}
							size={"large"}
							to={"mailto:info@omniumai.com"}>
						Get Early Access
					</Button>
				</Box>
			</Grid>
			<Grid item xs={8}>
				<Box sx={{px: 2, my: 'auto'}}>
					<img src="/assets/CibusAI.svg" alt="CibusAI" width="100%"/>
				</Box>
			</Grid>
		</>
	)
}

const MobileDashboardSection = () => {
	return (
		<>
			<Grid item xs={12}>
				<Typography variant="h1">
					Reimagining food
				</Typography>
				<Box sx={{pr: 1, pt: 2}}>
					<img src="/assets/CibusAI.svg" alt="CibusAI" width="100%"/>
				</Box>
			</Grid>
			<Grid item xs={12} sx={{pb: 5}}>
				<Typography
					color="text.secondary"
					variant="h4"
					paragraph
				>
					<Typography color={"primary"}
								component={'span'}
								variant={"h4"}>
						CibusAI {' '}
					</Typography>
					empowers food companies with AI-driven reformulation, reimagining their products for better
					<Typography color={"success.main"}
								component={'span'}
								variant={"h4"}>{' '} taste {' '}
					</Typography> and
					improved <Typography color={"error.main"}
										 component={'span'}
										 variant={"h4"}>health
				</Typography>.
				</Typography>
				<Box sx={{mx: 'auto', textAlign: 'center', pt: 3}}>
					<Button color={'primary'}
							variant="contained"
							component={RouterLink}
							size={"large"}
							to={"mailto:info@omniumai.com"}>
						Get Early Access
					</Button>
				</Box>
			</Grid>
		</>
	)
}

// ----------------------------------------------------------------------

export default function HomePage() {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<>
			<Helmet>
				<title> CibusAI </title>
			</Helmet>
			<Container maxWidth="xl" sx={{minHeight: '75vh', px: {xs: 1, md: 3}, py: 3}}>
				<Stack spacing={{xs: 5, sm: 10, md: 15}}>
					<div>
						<Element id='product' name='product'>
							<Grid
								container
								direction="row"
								justifyContent="center"
								alignItems="center"
								spacing={3}
							>
								{matches ? <DesktopDashboardSection/> : <MobileDashboardSection/>}
							</Grid>
						</Element>
					</div>
					<div>
						<Grid container
							  spacing={10}
							  justifyContent="center"
							  alignItems="stretch">
							<Grid item xs={12} md={4}>
								<Stack
									justifyContent="center"
									alignItems="center"
									spacing={3}
								>
									<Avatar
										sx={{
											backgroundColor: 'warning.main',
											height: 100,
											width: 100
										}}
									>
										<AccessTime sx={{fontSize: 50}}/>
									</Avatar>
									<Box sx={{textAlign: "center", mt: 1}}>
										<Typography variant={"h3"}>
											Finding alternative<br/>
											ingredients is slow
										</Typography>
										<Typography sx={{mt: 2}}>
											CibusAI can be used to quickly find alternative ingredients having similar
											functional
											and
											organoleptic properties.
											Short lead times allow you to quickly respond to market changes.
										</Typography>
									</Box>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4}>
								<Stack
									justifyContent="center"
									alignItems="center"
									spacing={3}
								>
									<Avatar
										sx={{
											backgroundColor: 'success.main',
											height: 100,
											width: 100
										}}
									>
										<PeopleOutline sx={{fontSize: 50}}/>
									</Avatar>
									<Box sx={{textAlign: "center", mt: 1}}>
										<Typography variant={"h3"}>
											Meeting user expectations<br/>
											is challenging
										</Typography>
										<Typography sx={{mt: 2}}>
											Taste (45%), food safety (42%), and cost (40%) are the main factors influencing
											consumers' food purchases.<sup>1</sup> CibusAI can help you reformulate your products to meet these expectations.
										</Typography>
									</Box>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4}>
								<Stack
									justifyContent="center"
									alignItems="center"
									spacing={3}
								>
									<Avatar
										sx={{
											backgroundColor: 'error.main',
											height: 100,
											width: 100
										}}
									>
										<FavoriteBorder sx={{fontSize: 50}}/>
									</Avatar>
									<Box sx={{textAlign: "center", mt: 1}}>
										<Typography variant={"h3"}>
											Healthy products<br/>
											sustainable diets
										</Typography>
										<Typography sx={{mt: 2}}>
											Consumers consider food being healthy for them (74%) as the most important
											aspect
											of a sustainable diet.<sup>1</sup> CibusAI allows you to find healthier alternatives to your ingredients.
										</Typography>
									</Box>
								</Stack>
							</Grid>
						</Grid>
					</div>
					<div>
						<Typography variant={"h2"} paragraph>
							Design your next food product with CibusAI
						</Typography>
						<Grid container alignItems="center" justifyContent="flex-start" spacing={3}>
							<Grid item xs={12} md={6} lg={4}>
								<UseCaseCard
									useCase={{
										cover: `/assets/yogurt.jpg`,
										title: "Flavor Screening",
										subTitle: "Identify suitable artificial food additives' replacements for Sweet and Bitter."
									}}
									index={0}/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<UseCaseCard
									useCase={{
										cover: `/assets/industrial.jpg`,
										title: "Toxicity Screening",
										subTitle: "Bet on biologically safe products.",
										future: true
									}}
									index={0}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<UseCaseCard
									useCase={{
										cover: `/assets/antioxidant.jpg`,
										title: "Antioxidant Activity",
										subTitle: "Healthier products, healthier customers.",
										future: true
									}}
									index={0}/>
							</Grid>
						</Grid>
					</div>
					<div>
						<Element id='privacy' name='privacy'>
							<Grid container
								  justifyContent="center"
								  alignItems="center"
								  spacing={3}>
								<Grid item xs={12} md={6}>
									<Typography variant={"h2"} paragraph>
										IP-Protection <br/>
										First
									</Typography>
									<Typography>
										We have a data and intellectual property protection first policy. The data you
										provide is kept safe, while all results are yours to keep.
									</Typography>
								</Grid>
								<Grid item xs={12} md={6}>
									<Stack
										justifyContent="center"
										alignItems="center"
									>
										<Grid
											container
											direction={'row'}
											spacing={3}
											justifyContent="center"
											alignItems="center"
										>
											<Grid item xs={4} sx={{textAlign: 'center'}}>
												<VisibilityOff sx={{fontSize: 80}} color={"error"}/>
												<Typography variant={'h4'} color={'inherit'}>
													Private
												</Typography>
											</Grid>
											<Grid item xs={4} sx={{textAlign: 'center'}}>
												<Security sx={{fontSize: 80}} color={"warning"}/>
												<Typography variant={'h4'} color={'inherit'}>
													Safe
												</Typography>
											</Grid>
											<Grid item xs={4} sx={{textAlign: 'center'}}>
												<DiamondOutlined sx={{fontSize: 80}} color={"success"}/>
												<Typography variant={'h4'} color={'inherit'}>
													Yours
												</Typography>
											</Grid>
										</Grid>
									</Stack>
								</Grid>
							</Grid>
						</Element>
					</div>
					<div>
						<Grid container
							  justifyContent="center"
							  alignItems="center"
							  spacing={3}>
							<Grid item xs={12} sx={{textAlign: 'center'}}>
								<Element id='contact' name='contact'>
									<Stack
										sx={{py: 10}}
										justifyContent="center"
										alignItems="center"
										spacing={3}>
										<>
											<Typography variant={"h2"}>
												Accelerate the Flavor Revolution
											</Typography>
											<Typography>
												Contact us to get early access!
											</Typography>
										</>
										<Button color={'primary'}
												variant="contained"
												component={RouterLink}
												size={"large"}
												to={"mailto:info@omniumai.com"}>
											Get Early Access
										</Button>
									</Stack>
								</Element>
							</Grid>
							<Grid item xs={12} sx={{textAlign: 'center'}}>
								<Stack direction={{ xs: 'column', md: 'row' }}
									   spacing={{ xs: 2, md: 4}}
									   justifyContent="center"
									   alignItems="center">
									<Typography variant={"h2"}>
										A Product By
									</Typography>
									<Link
										underline="none"
										component={RouterLink}
										target="_blank"
										rel="noopener"
										to={"https://www.omniumai.com"}
									>
										<img src="/assets/logo_extended.svg" alt="OmniumAI" height={100}/>
									</Link>
								</Stack>
							</Grid>
						</Grid>
					</div>
				</Stack>
			</Container>
		</>
	)
}

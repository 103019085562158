import {Navigate, useRoutes} from 'react-router-dom';

// layouts
import BodyLayout from './layouts/body';
import SimpleLayout from './layouts/simple';

// pages
import Page404 from './pages/Page404';
import HomePage from './pages/HomePage';
import PrivacyPage from "./pages/PrivacyPage";

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: '',
            element: <>
                <BodyLayout/>
            </>,
            children: [
                {element: <Navigate to="home"/>, index: true},
                {path: 'home', element: <HomePage/>},
                {path: 'privacy', element: <PrivacyPage/>},
            ],
        },
        {
            element: <SimpleLayout/>,
            children: [
                {element: <Navigate to="overview"/>, index: true},
                {path: '404', element: <Page404/>},
                {path: '*', element: <Navigate to="/404"/>},
            ],
        },
        {
            path: '*',
            element: <Navigate to="/404" replace/>,
        },
    ]);
}

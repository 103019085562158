import PropTypes from 'prop-types';
// @mui
import {alpha, styled} from '@mui/material/styles';
import {Card, CardContent, Stack} from '@mui/material';
//
import SvgColor from '../svg-color';
import Label from "../label";

// ----------------------------------------------------------------------

const StyledCardMedia = styled('div')({
	position: 'relative',
	paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled('a')({
	overflow: 'hidden',
	WebkitLineClamp: 2,
	display: '-webkit-box',
	WebkitBoxOrient: 'vertical',
});

const StyledCover = styled('img')({
	top: 0,
	width: '100%',
	height: '100%',
	objectFit: 'cover',
	position: 'absolute',
});

// ----------------------------------------------------------------------

UseCaseCard.propTypes = {
	useCase: PropTypes.object.isRequired,
	index: PropTypes.number,
};

export default function UseCaseCard({useCase, index}) {
	const {cover, title, subTitle, future} = useCase;
	const latestPostLarge = index === 0;
	const latestPost = index === 1 || index === 2;

	return (
		<Card sx={{position: 'relative'}}>
			<StyledCardMedia
				sx={{
					...((latestPostLarge || latestPost) && {
						pt: 'calc(100% * 4 / 3)',
						'&:after': {
							top: 0,
							content: "''",
							width: '100%',
							height: '100%',
							position: 'absolute',
							bgcolor: (theme) => alpha(theme.palette.grey[900], 0.40),
						},
					}),
					...(latestPostLarge && {
						pt: {
							xs: 'calc(100% * 4 / 3)',
							sm: 'calc(100% * 3 / 4.66)',
						},
					}),
				}}
			>
				<SvgColor
					color="paper"
					src="/assets/icons/shape-avatar.svg"
					sx={{
						width: 80,
						height: 36,
						zIndex: 9,
						bottom: -15,
						position: 'absolute',
						color: 'background.paper',
						...((latestPostLarge || latestPost) && {display: 'none'}),
					}}
				/>
				<StyledCover alt={title} src={cover}/>
			</StyledCardMedia>

			<CardContent
				sx={{
					pt: 4,
					...((latestPostLarge || latestPost) && {
						bottom: 0,
						width: '100%',
						position: 'absolute',
					}),
				}}
			>
				<Stack
					direction={"row"}
					// alignItems={"center"}
					spacing={1}
				>
					<StyledTitle
						color="inherit"
						variant="subtitle1"
						sx={{
							typography: 'h4',
							color: 'common.white',
						}}
						href={useCase.link}
					>
						{title}
					</StyledTitle>
					{future ? <Label color={"info"} variant={"ghost"}>Future</Label> : null}
				</Stack>
				<StyledTitle
					color="inherit"
					variant="subtitle2"
					sx={{
						color: 'common.white',
					}}
				>
					{subTitle}
				</StyledTitle>
			</CardContent>
		</Card>
	);
}
